import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import Trix from 'trix' // Trix is used thru the ujs pipeline
import '../../../node_modules/uikit/src/scss/uikit'
import '../uikit/iconsmind'
import '../uikit/ggift-theme'
import '../uikit/greatest-journey-theme'
import 'controllers'
import 'images'

require('@rails/ujs').start()
// require("turbolinks").start()
require('@rails/activestorage').start()

// loads the Icon plugin
UIkit.use(Icons)
// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.');
