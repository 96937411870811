import { Controller } from 'stimulus'
import UIkit from 'uikit'

export default class extends Controller {
  connect () {
    const flashError = this.element.getElementsByClassName('flash-error')
    const flashNotice = this.element.getElementsByClassName('flash-notice')
    const flashAlert = this.element.getElementsByClassName('flash-alert')
    const flashes = [flashError, flashNotice, flashAlert]

    flashes.forEach(function (flash) {
      if (flash[0].innerHTML.length > 0) {
        const flashClass = flash[0].className
        let flashStatus = ''

        switch (flashClass) {
          case 'flash-error':
            flashStatus = 'warning'
            break
          case 'flash-alert':
            flashStatus = 'danger'
            break
          default:
            flashStatus = 'success'
        }

        UIkit.util.ready(function () {
          UIkit.notification(
            {
              message: flash[0].innerHTML,
              pos: 'top-center',
              timeout: 10000,
              status: flashStatus
            }
          )
        })
      }
    })
  }
}
