import NestedItemsController from './nested_items_controller'

export default class extends NestedItemsController {
  renderTemplate(index) {
    const div = document.createElement('div')
    div.className = 'uk-grid-match uk-child-width-1-3@s uk-grid uk-margin-small';
    div.setAttribute('uk-grid', '')
    div.setAttribute('data-index', index)
    div.innerHTML = `
      <div class="field uk-first-column">
        <label class="uk-form-label" for="training_teachers_attributes_` + index + `_person_attributes_name">Vārds, Uzvārds</label>
        <input class="uk-input" type="text" name="training[teachers_attributes][` + index + `][person_attributes][name]" id="training_teachers_attributes_` + index + `_person_attributes_name">
      </div>
      <div class="field">
        <label class="uk-form-label" for="training_teachers_attributes_` + index + `_person_attributes_phone">Tālruņa numurs</label>
        <input class="uk-input" type="text" name="training[teachers_attributes][` + index + `][person_attributes][phone]" id="training_teachers_attributes_` + index + `_person_attributes_phone">
      </div>
      <div class="field">
        <label class="uk-form-label" for="training_teachers_attributes_` + index + `_person_attributes_email">E-pasts</label>
        <input class="uk-input" type="text" name="training[teachers_attributes][` + index + `][person_attributes][email]" id="training_teachers_attributes_` + index + `_person_attributes_email">
      </div>
    `

    this.listTarget.appendChild(div)
  }
}

