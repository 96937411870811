import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    const fieldsWithErrors = this.element.querySelectorAll('.field_with_errors input')
    const areasWithErrors = this.element.querySelectorAll('.field_with_errors textarea')
    const placeholdersWithErrors = this.element.querySelectorAll('.uk-placeholder .field_with_errors')

    fieldsWithErrors.forEach(function (field) {
      field.classList.add('form-danger')
    })
    areasWithErrors.forEach(function (field) {
      field.classList.add('form-danger')
    })
    placeholdersWithErrors.forEach(function (field) {
      field.closest('.uk-placeholder').classList.add('form-danger')
    })
  }
}
