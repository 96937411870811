import { Controller } from 'stimulus'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = [ 'visited' ]

  connect () {
    const userVisit = this.element.getAttribute('data-cookies-visited')
    const cookieContent = this.element.innerHTML

    if (userVisit === 'first_time' || userVisit === '') {
      UIkit.util.ready(function () {
        UIkit.notification(
          {
            message: cookieContent,
            pos: 'bottom-left',
            timeout: null
          }
        )
      })
    }
  }
}
