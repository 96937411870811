import { Controller } from 'stimulus'

export default class NestedItemsController extends Controller {
  static targets = [ 'list' ]

  add_item (e) {
    e.preventDefault()
    const items = document.querySelectorAll('[data-index]')
    const lastItem = items[items.length - 1]
    if  (typeof lastItem !== "undefined") {
      let currentIndex = lastItem.dataset.index
      this.index = Number(currentIndex) + 1
    } else {
      this.index = 0
    }

    this.renderTemplate(this.index)
  }

  renderTemplate(index) {
    console.error("should be implemented in children class", index)
  }
}

