import NestedItemsController from './nested_items_controller'

export default class extends NestedItemsController {
  renderTemplate(index) {
    const giftsLabel = this.element.querySelector('label[for$=\'gifts\']').innerText
    const commentLabel = this.element.querySelector('label[for$=\'comment\']').innerText
    const div = document.createElement('div')
    div.className = 'uk-grid-match uk-child-width-1@s uk-grid uk-margin-small uk-margin-medium-bottom';
    div.setAttribute('uk-grid', '')
    div.setAttribute('data-index', index)
    div.innerHTML = `
      <div class="field uk-width-1-3@s uk-first-column">
        <label class="uk-form-label" for="report_events_attributes_` + index + `_gifts">` + giftsLabel + `</label>
        <input min="0" data-target="reported-gifts.element" data-action="input->reported-gifts#calculateTotal" class="uk-input" type="number" name="report[events_attributes][` + index + `][gifts]" id="report_events_attributes_` + index + `_gifts">
      </div>
      <div class="field uk-grid-margin uk-first-column">
        <label class="uk-form-label" for="report_events_attributes_` + index + `_comment">` + commentLabel + `</label>
        <textarea class="uk-textarea" rows="4" name="report[events_attributes][` + index + `][comment]" id="report_events_attributes_` + index + `_comment"></textarea>
      </div>
    `

    this.listTarget.appendChild(div)
  }
}

