import NestedItemsController from './nested_items_controller'

export default class extends NestedItemsController {
  renderTemplate(index) {
    const admittedLabel = this.element.querySelector('label[for$=\'admitted\']').innerText
    const finishedLabel = this.element.querySelector('label[for$=\'finished\']').innerText
    const repentedLabel = this.element.querySelector('label[for$=\'repented\']').innerText
    const prayingLabel = this.element.querySelector('label[for$=\'praying\']').innerText
    const commentLabel = this.element.querySelector('label[for$=\'comment\']').innerText
    const div = document.createElement('div')
    div.className = 'uk-grid-match uk-child-width-1@s uk-grid uk-margin-small uk-margin-medium-bottom';
    div.setAttribute('uk-grid', '')
    div.setAttribute('data-index', index)
    div.innerHTML = `
      <div class="field uk-width-1-2@s uk-first-column">
        <label class="uk-form-label" for="report_courses_attributes_` + index + `_kids_admitted">` + admittedLabel + `</label>
        <input min="0" class="uk-input" type="number" name="report[courses_attributes][` + index + `][kids_admitted]" id="report_courses_attributes_` + index + `_kids_admitted">
      </div>
      <div class="field uk-width-1-2@s">
        <label class="uk-form-label" for="report_courses_attributes_` + index + `_kids_finished">` + finishedLabel + `</label>
        <input min="0" class="uk-input" type="number" name="report[courses_attributes][` + index + `][kids_finished]" id="report_courses_attributes_` + index + `_kids_finished">
      </div>
      <div class="field uk-width-1-2@s uk-grid-margin uk-first-column">
        <label class="uk-form-label" for="report_courses_attributes_` + index + `_kids_repented">` + repentedLabel + `</label>
        <input min="0" class="uk-input" type="number" name="report[courses_attributes][` + index + `][kids_repented]" id="report_courses_attributes_` + index + `_kids_repented">
      </div>
      <div class="field uk-width-1-2@s uk-grid-margin">
        <label class="uk-form-label" for="report_courses_attributes_` + index + `_kids_praying">` + prayingLabel + `</label>
        <input min="0" class="uk-input" type="number" name="report[courses_attributes][` + index + `][kids_praying]" id="report_courses_attributes_` + index + `_kids_praying">
      </div>
      <div class="field uk-grid-margin uk-first-column">
        <label class="uk-form-label" for="report_courses_attributes_` + index + `_comment">` + commentLabel + `</label>
        <textarea class="uk-textarea" rows="4" name="report[courses_attributes][` + index + `][comment]" id="report_courses_attributes_` + index + `_comment" spellcheck="false"></textarea>
      </div>
    `

    this.listTarget.appendChild(div)
  }
}

