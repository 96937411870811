import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'page', 'loader' ]

  initialize () {
    this.pageTarget.style.display = 'none'
    this.loaderTarget.style.display = 'block'
  }

  connect () {
    const page = this.pageTarget
    const loader = this.loaderTarget

    window.addEventListener('load', function () {
      page.style.display = 'block'
      loader.style.display = 'none'
    })
  }
}
