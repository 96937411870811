import { Controller } from 'stimulus'
import UIkit from 'uikit'

export default class extends Controller {
  static targets = [ 'warning' ]

  open (e) {
    if (e.target.getAttribute('data-enabled')) {
      return
    } else {
      e.preventDefault();

      const warning = this.warningTarget.innerHTML
      UIkit.util.ready(function () {
        UIkit.notification(
          {
            message: warning,
            pos: 'top-center',
            timeout: null,
            status: 'warning'
          }
        )
      })
    };
  }
}
