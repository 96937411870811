import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "element", "total" ]

  connect () {
    this.calculateTotal()
  }

  calculateTotal() {
    let baseValue = 0

    this.elementTargets.forEach(function (element) {
      baseValue += parseInt(element.value || 0)
    })

    this.totalTarget.textContent = baseValue
  }
}
